<div class="search-bar" *ngIf="this.placeSearchCriteria" [ngClass]="{'filtered':filterOn || fullScreenMode}">

    <a class="close-filter" *ngIf="!fullScreenMode" (click)="hideFilter()"><pb-icon name="cross"></pb-icon></a>

    <h6 class="title">{{heading}}</h6>

    <div class="fieldsBox location" [ngClass]="{'full-screen': fullScreenMode}">
        
        <validating-control-container namespace="" [validationErrors]="errors">
            <pb-geo-input id="sb-search-area" caption="Location" placeholder="City, town or postcode" [(query)]="query" [(searchArea)]="searchArea" (SearchAreaChanged)="onSearchAreaChanged($event)" (blur)="onBlur()"></pb-geo-input>
        </validating-control-container>

        <pb-dropdown id="sb-radius" classes="field" caption="Radius" [options]="this.radiusOptions" [(selectedValue)]="placeSearchCriteria.radius" (selectedValueChange)="applyFilter()"></pb-dropdown>
    </div>

    <div *ngIf="fullScreenMode" class="fieldsBox types">
        <pb-dropdown classes="field" caption="For Sale/To Rent" [options]="this.listingTypesArray" [(selectedValue)]="placeSearchCriteria.searchTypeId" (selectedValueChange)="applyFilter()"></pb-dropdown>
    </div>

    <div class="fieldsBox sizes">
        <pb-dropdown id="sb-min-beds" classes="field" firstNullValue="Min beds" caption="Min beds" [options]="placeSearchCriteria.searchTypeId==1?this.sizesRentArray:this.sizesSaleArray" [(selectedValue)]="placeSearchCriteria.minNumberOfBedroomsId" (selectedValueChange)="applyFilter()"></pb-dropdown>
        <pb-dropdown id="sb-max-beds" classes="field" firstNullValue="Max beds" caption="Max beds" [options]="placeSearchCriteria.searchTypeId==1?this.sizesRentArray:this.sizesSaleArray" [(selectedValue)]="placeSearchCriteria.maxNumberOfBedroomsId" (selectedValueChange)="applyFilter()"></pb-dropdown>
    </div>

    <div class="fieldsBox prices">
        <pb-dropdown id="sb-min-price" classes="field" firstNullValue="Min price" caption="Min price" [options]="placeSearchCriteria.searchTypeId==1?this.priceTypesRentArray:this.priceTypesSaleArray" [(selectedValue)]="placeSearchCriteria.minPrice" (selectedValueChange)="applyFilter()"></pb-dropdown>
        <pb-dropdown id="sb-max-price" classes="field" firstNullValue="Max price" caption="Max price" [options]="placeSearchCriteria.searchTypeId==1?this.priceTypesRentArray:this.priceTypesSaleArray" [(selectedValue)]="placeSearchCriteria.maxPrice" (selectedValueChange)="applyFilter()"></pb-dropdown>
    </div>

    <div class="fieldsBox property-types">
        <multi-select-dropdown id="sb-property-types" showNumberOfSelectedOptions="true" selectedValueAsKeys="true" placeholder="Property types" [options]="this.placeTypesArray" [(selectedValues)]="placeSearchCriteria.placeTypeId" (selectedValuesChange)="applyFilter()"></multi-select-dropdown>
    </div>

    <div class="fieldsBox listed">
        <pb-dropdown id="sb-listed" classes="field" caption="Listed" [options]="listedOptions" [(selectedValue)]="placeSearchCriteria.listed" (selectedValueChange)="applyFilter()"></pb-dropdown>
    </div>
    <div class="filter-button-container">
        <pb-button extraClasses="filter-button" buttonStyle="primary" caption="Filter" leftIcon="filter" (clicked)="showFilter()" iconColor="#333333"></pb-button>
    </div>
    <div class="apply-filter-container">
        <pb-button extraClasses="clear-button" buttonStyle="tertiary" caption="Clear" (clicked)="clearFilter()"></pb-button>
        <pb-button extraClasses="search-button" buttonStyle="primary" caption="{{this.buttonCaption}}" leftIcon="search" (clicked)="updateSearch()"></pb-button>
    </div>
    
</div>  

