import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiSelectDropDownComponent } from 'src/app/controls/multi-select-dropdown/multi-select-dropdown.component';
import { PbButtonComponent } from 'src/app/controls/pb-button/pb-button.component';
import { PbDropdownComponent } from 'src/app/controls/pb-dropdown/pb-dropdown.component';
import { PbGeoInputComponent } from 'src/app/controls/pb-geo-input/pb-geo-input.component';
import { PbIconComponent } from 'src/app/controls/pb-icon/pb-icon.component';
import { PbTextboxComponent } from 'src/app/controls/pb-textbox/pb-textbox.component';
import { PlaceSearchCriteria } from 'src/app/infrastructure/model/placeSearchCriteria';
import { AreObjectsEqual, CopyObject } from 'src/app/infrastructure/object.helpers';
import { KeyValuePair } from 'src/app/infrastructure/types';
import { GqlError } from 'src/app/services/graphql.module/model';
import { lookupData } from 'src/app/services/initialization.module/Initialization.data';
import { GeoSearchService } from 'src/app/services/search.module/geosearch.service';
import { AutoCompleteResult } from 'src/app/services/search.module/model/autoComplete';
import { PlaceSearchResult } from 'src/app/services/search.module/model/geo';

@Component({
  selector: 'pb-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PbButtonComponent, PbDropdownComponent, PbIconComponent, CommonModule, PbTextboxComponent, PbGeoInputComponent, MultiSelectDropDownComponent]
})
export class PbSearchBarComponent {

  constructor(
    private locationsService: GeoSearchService,
    private cd: ChangeDetectorRef,
  ) { }

  _placeSearchCriteria: PlaceSearchCriteria;
  searchArea: AutoCompleteResult;
  placeTypes:string;
  prices:string;
  sizes:string;

  _oldPlaceSearchCriteria: PlaceSearchCriteria;

  listingTypesArray : Array<KeyValuePair<number, string>>= lookupData.listingTypes;
  placeTypesArray : Array<KeyValuePair<number, string>>= lookupData.propertyTypes;
  sizesSaleArray: Array<KeyValuePair<number, string>> = lookupData.saleNumberOfBedrooms;
  sizesRentArray: Array<KeyValuePair<number, string>> = lookupData.rentNumberOfBedrooms;
  priceTypesSaleArray: Array<KeyValuePair<number, string>> = lookupData.salePrices;
  priceTypesRentArray: Array<KeyValuePair<number, string>> = lookupData.rentPrices;
  radiusOptions: Array<KeyValuePair<number, string>> = lookupData.radiuses;
  listedOptions: Array<KeyValuePair<number, string>> = lookupData.listed;
  
 
  errors: GqlError[];

  @Output() searchClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchAreaChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() heading = "Filter your results";
  @Input() buttonCaption = "Update results";
  @Input() fullScreenMode: boolean = false;
  @Input() placeSearchResult: PlaceSearchResult = null;  

  query: string;

  @Input() filterOn: boolean = false;

  @Input() set placeSearchCriteria(value: PlaceSearchCriteria) {
    
    if (!value){
      return;
    }
      //Place Types
    if (value.placeTypeId && value.placeTypeId.length>0) {
      var placeTypesSource = "";
      value.placeTypeId.forEach(element => {
        placeTypesSource += this.getPlaceTypeById(element) + ", ";
      });
      this.placeTypes = placeTypesSource.substring(0, placeTypesSource.length - 2);
    }
    else{
      this.placeTypes = "Houses & flats";
    }
    
    //Sizes
    if (value.minNumberOfBedroomsId && value.maxNumberOfBedroomsId) {
      this.sizes = value.minNumberOfBedroomsId + " - " + value.maxNumberOfBedroomsId + " bedroom";
    }
    else if (value.minNumberOfBedroomsId) {
      this.sizes = value.minNumberOfBedroomsId + "+ bedroom";
    }
    else if (value.maxNumberOfBedroomsId) {
      this.sizes = value.maxNumberOfBedroomsId + "- bedroom";
    }
    else{
      this.sizes = "Any size";
    }

    //Prices
    if (value.minPrice && value.maxPrice) {
      this.prices = this.formatNumberAsCurrency(value.minPrice) + " - " + this.formatNumberAsCurrency(value.maxPrice);
    }
    else if (value.minPrice) {
      this.prices = this.formatNumberAsCurrency(value.minPrice) + "+";
    }
    else if (value.maxPrice) {
      this.prices = this.formatNumberAsCurrency(value.maxPrice)  + "-";
    }
    else{
      this.prices = "Any price";
    }

    this.searchArea = {
      description: value.locationName,
      latitude: value.latitude,
      longitude: value.longitude,
      types: [value.type],
      localityId: value.localityId,
      isPreviousSearch: false
    }

    this.storeAutoCompleteResultInPlaceSearchResult(this.searchArea);


    this._placeSearchCriteria = value;    
    if (!this._placeSearchCriteria.searchTypeId){
      this._placeSearchCriteria.searchTypeId = 2;
    }


  }

  get placeSearchCriteria() {
    return this._placeSearchCriteria;
  }

  formatNumberAsCurrency(value: number) {
    return "£" + value.toLocaleString('en-GB', { maximumFractionDigits: 0 });
  }

  getPlaceTypeById(id: number) {
    var result = this.placeTypesArray.find(x => x.key == id);
    if (result) {
      return result.value;
    }
    return "";
  }

  showFilter(){
    this._oldPlaceSearchCriteria = CopyObject(this.placeSearchCriteria);
    this.filterOn = true;
  }

  hideFilter(){
    this.placeSearchCriteria = CopyObject(this._oldPlaceSearchCriteria);
    this.filterOn = false;
  }

  clearFilter(){
    this._oldPlaceSearchCriteria = CopyObject(this.placeSearchCriteria);
    this.placeSearchCriteria.minNumberOfBedroomsId = null;
    this.placeSearchCriteria.maxNumberOfBedroomsId = null;
    this.placeSearchCriteria.minPrice = null;
    this.placeSearchCriteria.maxPrice = null;
    this.placeSearchCriteria.placeTypeId = [];
    this.placeSearchCriteria.radius = null;
    this.placeSearchCriteria.page = 1;
  }

  applyFilter(){
    if (!this.filterOn){
      this.errors = new Array<GqlError>();

      if (!AreObjectsEqual(this._oldPlaceSearchCriteria,this.placeSearchCriteria)){
        
        if (!this.placeSearchResult){
          this.errors.push({message: "Please enter/select a valid location", source: ""});
        }
        else{
          this.placeSearchCriteria.page = 1;
          this.searchClicked.emit(this.placeSearchCriteria);
        }
        
      }

      if (!this.fullScreenMode){
        this.filterOn = false;
      }
      
    }
  }

  updateSearch(){
    this.errors = new Array<GqlError>();




    if (!AreObjectsEqual(this._oldPlaceSearchCriteria,this.placeSearchCriteria)){

      if (!this.placeSearchResult){
        this.errors.push({message: "Please enter/select a location", source: ""});
        this.searchAreaChanged.emit(null);
      }
      else{
        this.searchClicked.emit(this.placeSearchCriteria);
      }
      
    }

    if (!this.fullScreenMode){
      this.filterOn = false;
    }
  }


  onSearchAreaChanged(autoCompleteResult:any){
    this.errors = new Array<GqlError>();

  
    if (autoCompleteResult && autoCompleteResult.description){
      //This is an auto complete result
      if (!autoCompleteResult){
        this.errors = new Array<GqlError>();
        this.errors.push({message: "Please enter/select a location", source: ""});
        this.searchAreaChanged.emit(null);
        return;
      }

      this.storeAutoCompleteResultInPlaceSearchResult(autoCompleteResult);

    this.placeSearchCriteria.administrativeAreaPathSegment = null;
    this.placeSearchCriteria.localityPathSegment = null;
    this.placeSearchCriteria.subLocalityPathSegment = null;
    this.placeSearchCriteria.latitude = this.placeSearchResult.geoSearchResult.latitude;
    this.placeSearchCriteria.longitude = this.placeSearchResult.geoSearchResult.longitude;
    this.placeSearchCriteria.locationName = this.placeSearchResult.geoSearchResult.searchArea;
    this.placeSearchCriteria.type = this.placeSearchResult.geoSearchResult.type;
    this.placeSearchCriteria.radius = null;
    this.placeSearchCriteria.localityId = this.placeSearchResult.geoSearchResult.localityId;


    this.searchAreaChanged.emit(this.placeSearchResult);




    this.applyFilter();
    }
    else{
      //this is a text result
      this.locationsService.PerformGeocoderSearch(autoCompleteResult, true).then((data) => {
                    
        if (!data || !data.geoSearchResult) {
            this.errors = new Array<GqlError>();
            this.errors.push({message: "Please enter/select a valid location", source: ""});  
            this.searchAreaChanged.emit(null);                
            this.cd.detectChanges();
        }
        else{
            this.placeSearchResult = data;
            if (!this.fullScreenMode){
              this.locationsService.NavigateToSearchResults(this.placeSearchResult, this.placeSearchCriteria.searchTypeId);
            }
            else{
              this.searchAreaChanged.emit(this.placeSearchResult);  
            }
            return;
        }
    });
    }

  }
  
  onBlur(){
    if (this.fullScreenMode){
      this.onSearchAreaChanged(this.query);
    }
  }

  private storeAutoCompleteResultInPlaceSearchResult(autoCompleteResult: AutoCompleteResult) {
    this.placeSearchResult = {
      geoSearchResult: {
        latitude: autoCompleteResult.latitude,
        longitude: autoCompleteResult.longitude,
        type: autoCompleteResult.types[0],
        locationName: autoCompleteResult.description,
        searchArea: autoCompleteResult.description,
        localityId: autoCompleteResult.localityId
      },
      errors: new Array<GqlError>()
    };
  }
}
